/*
 * @Author: your name
 * @Date: 2022-01-10 13:45:58
 * @LastEditTime: 2022-12-19 10:50:04
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\views\index\index.tsx
 */
import { defineComponent, onMounted, ref } from 'vue';
import { companyData, solutionData, productData, newsData } from './indexData'
import { useRouter } from 'vue-router'
import './index.scss'
import carousel from '@/components/carousel'


/**
 * 标题
 */
const headerTitle = defineComponent({
    props: {
        title: {
            type: String
        },
        conten: {
            type: String
        },
        right: {
            type: Boolean,
            default: () => {
                return false
            }
        }
    },
    setup(props, cex) {
        return () => (
            <div class="index-item-header">
                <div style={{ justifyContent: props.right ? 'flex-end' : '' }} class="index-item-header-title">
                    <div>
                        <p style={{ color: props.right ? '#fff' : '' }}>{props.title}</p>
                        <span>{props.conten}</span>
                    </div>
                </div>
            </div>
        )

    },
});

/**
 * 公司简介
 */
const companyProfile = defineComponent({
    props: {
        title: {
            type: String
        },
        conten: {
            type: String
        },
    },
    setup(props, cex) {
        return () => (
            <div class="index-companyProfile" id='companyProfile'>
                <headerTitle title="公司简介" conten="COMPANY PROFILE" />
                <div class="index-companyProfile-body">
                    <div class="index-companyProfile-body-top">
                        <div class="index-companyProfile-body-top-left"></div>
                        <div class="index-companyProfile-body-top-right">
                            <div class="index-companyProfile-body-top-right-font">
                                {companyData.text}
                                <a class="index-news-newsbody-item-text-button" href='/companyProfile'>
                                    <el-icon size={22}>
                                        <right />
                                    </el-icon>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )

    },
});

/**
 * 解决方案
 */
const solution = defineComponent({
    props: {},
    setup(props, cex) {
        const router = useRouter()
        function setRouter(path: string, id?: string) {
            router.push({
                path,
                query: {
                    id
                }
            })
        }
        return () => (
            <div class="index-solution">
                <headerTitle title="解决方案" right={true} conten="THE SOLUTION" />
                <div class="index-solution-body">
                    <el-carousel interval={5000} indicator-position="outside" trigger="hover" arrow="never" height="570px">
                        {
                            solutionData.map((item, index) => {

                                return <el-carousel-item >
                                    <div class="index-solution-body-imgBox">
                                        {
                                            item.img.map((v: string) => {
                                                return <img src={v} alt="" />
                                            })
                                        }
                                    </div>
                                    <div class="index-solution-body-text">
                                        <div class="index-solution-body-text-tiem">
                                            {item.date}
                                        </div>
                                        <div>
                                            <h4>{item.title}</h4>
                                            <span>{item.text}</span>
                                            <span class="index-news-newsbody-item-text-button" onClick={() => setRouter('/solution', '#solution' + index)}>
                                                <el-icon size={22} color="#fff">
                                                    <right />
                                                </el-icon>
                                            </span>
                                        </div>
                                    </div>
                                </el-carousel-item>
                            })
                        }

                    </el-carousel>

                </div>
            </div>
        )

    },
});

/**
 * 产品中心
 */

const productCenter = defineComponent({
    props: {},
    setup(props, cex) {
        const router = useRouter()
        function setRouter(path: string, id?: string) {
            router.push({
                path,
                query: {
                    id
                }
            })
        }
        return () => (
            <div class="index-productCenter">
                <headerTitle title="产品中心" conten="COMPANY PROFILE" />
                <div class="index-solution-body index-productCenter-body">

                    <div class="index-solution-body-imgBox">
                        {
                            productData[0].img.map((v: string) => {
                                return <img src={v} alt="" />
                            })
                        }
                    </div>
                    <div class="index-solution-body-text">
                        <div class="index-solution-body-text-tiem">
                            {productData[0].date}
                        </div>
                        <div>
                            <h4 style="color:#000">{productData[0].title}</h4>
                            <span>{productData[0].text}</span>
                            <span class="index-news-newsbody-item-text-button" onClick={() => setRouter('/productCenter', '#product0')}>
                                <el-icon size={22} color="#fff">
                                    <right />
                                </el-icon>
                            </span>
                        </div>

                    </div>
                </div>

                <div class=" index-solution-about">
                    <div class="index-solution-body-text">
                        <div class="index-solution-body-text-tiem">
                            {productData[0].date}
                        </div>
                        <div>
                            <h4 style="color:#000">{productData[0].title}</h4>
                            <span>{productData[0].text}</span>
                            <span class="index-news-newsbody-item-text-button" onClick={() => setRouter('/productCenter', '#product1')}>
                                <el-icon size={22} color="#fff">
                                    <right />
                                </el-icon>
                            </span>
                        </div>

                    </div>
                    <div class="index-solution-body-imgBox">
                        <img src={productData[0].img[0]} alt="" />
                    </div>
                </div>
            </div>
        )

    },
});

/**
 * 新闻动态
 */
const news = defineComponent({
    props: {},
    setup(props, cex) {
        const router = useRouter()
        function setRouter(path: string, id?: string) {
            router.push({
                path,
                query: {
                    id
                }
            }).then(()=>{
                window.scrollTo(0,0)
            })
        }
        return () => (
            <div class="index-news">
                <headerTitle title="新闻动态" conten="COMPANY PROFILE" right={true} />
                <div class="index-news-newsbody" >

                    <el-row gutter={20} style="background: #fff;">
                        <el-col span={12}>
                            <img src={newsData[0].img} alt="" />
                        </el-col>
                        <el-col span={12}>
                            <div class="index-news-newsbody-item">
                                <div class="index-news-newsbody-item-text">
                                    <div class="index-news-newsbody-item-text-tiem">
                                        {newsData[0].date}
                                    </div>
                                    <div>
                                        <h4>{newsData[0].title}</h4>
                                        <span>{newsData[0].text}</span>
                                        <span class="index-news-newsbody-item-text-button" onClick={()=>setRouter('/news')}>
                                            <el-icon size={22}>
                                                <right color="#fff" />
                                            </el-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>


                </div>
            </div>

        )

    },
})
export default defineComponent({
    props: {},
    components: { carousel },
    setup(props, cex) {
        const router = useRouter()
        onMounted(() => {
            window.onscroll = () => {
                if (router.currentRoute.value.path == '/index' && location.hash) {
                    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
                    if (scrollTop == 0) {
                        location.hash = ''
                    }
                }
            }
        })
        return () => (
            <div class="index">
                <div class="index-carousel">
                    <carousel />
                </div>
                <companyProfile />
                <solution />
                <productCenter />
                <news />
            </div>
        )

    },
});