/*
 * @Author: your name
 * @Date: 2022-01-18 14:01:11
 * @LastEditTime: 2022-12-16 13:48:51
 * @LastEditors: HuYuliu 10097355522qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \gaoshuofficialwebsite\src\views\index\indexData.ts
 */

export const  companyData = {
	text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
	img: '',
};

export const  solutionData = [
    {
        title:'智慧急救',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:[require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png')]
    },
    {
        title:'车辆管理',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:[require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png')]
    },
];

export const productData = [
    {
        title:'GX',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:[require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png')]
    },
    {
        title:'NIDA',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:[require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png')]
    },
    {
        title:'院前预告系统',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:[require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png'),require('@/assets/images/20220111154134.png')]
    },
];

export const newsData =[
    {
        title:'移动医疗',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:require('@/assets/images/20220111154134.png')
    },
    {
        title:'移动医疗',
        text: '上海高术科技发展有限公司是德国MedDV公司在中国的合作伙伴，授权销 售、安装、维护NIDA急救信息系统，并在此基础上针对中国急救医疗车信息 化应用，定制研发 NIDA+（Nidaplus）系统，满足新时期更高标准的急救 管理需求实现业务更高效、能力更加强、指挥更集中、操作更简易、全面提 升病患急救处理能力和突发事件的应急能力...',
        date:'2021',
        img:require('@/assets/images/20220111154134.png')
    },
]